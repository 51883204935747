/* eslint-disable */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import { loadState } from './utils/statePersistence';
import rootSaga from './sagas';

import { IState } from './types/state'
import { IAction } from './types/common'

const sagaMiddleware = createSagaMiddleware();
const user = loadState('user');

const rootReducer = (state: IState | undefined, action: IAction) => reducers(state, action);

const store = createStore(
  rootReducer,
  { user },
  compose(
    applyMiddleware(sagaMiddleware),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
  )
);

sagaMiddleware.run(rootSaga);

export default store;
