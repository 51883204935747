import React, { useEffect } from 'react';
// import ReactGA from 'react-ga';
// import { useLocation } from 'react-router-dom';
import GlobalStyles from './styles/global';
import './assets/sass/app.scss';
import './App.css';
import LandingPage from './scenes/LandingPage';

// let GA_INITIALIZED = false;

// function usePageViews() {
//   let location = useLocation();
//   useEffect(() => {
//     if (!GA_INITIALIZED) {
//       ReactGA.initialize('UA-198386458-1');
//       GA_INITIALIZED = true;
//     }
//     location = useLocation();
//     ReactGA.set({ page: location.pathname });
//     ReactGA.pageview(location.pathname);
//   });
// }

function App(): React.ReactElement {
  // ReactGA.initialize('UA-198386458-1');
  // ReactGA.pageview(window.location.pathname + window.location.search);
  // usePageViews();
  return (
    <>
      <LandingPage />
      <GlobalStyles />
    </>
  );
}

export default App;
