/* eslint-disable camelcase */
import styled from 'styled-components';
import { css } from '@emotion/react';
import { bool } from 'yup';

import bg from '../../assets/images/bg-landing.png';
import foguete from '../../assets/images/landing-3.png';
import menina from '../../assets/images/landing-2.png';
import menino from '../../assets/images/landing-8.png';

import icon_minus from '../../assets/images/accordion-minus.png';
import icon_plus from '../../assets/images/accordion-plus.png';
import landing4 from '../../assets/images/landing-4.png';
import bg_footer from '../../assets/images/bg-footer.png';
import icon_depoimento from '../../assets/images/icon-depoimento.png';

export const ContentSlide = styled.div`
  height: 200px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;

  ::before,
  ::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    width: 15px;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  ::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  ::before {
    left: 0;
    top: 0;
  }
`;

export const SlideTrack = styled.div`
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(250px * 10) > * {
    margin-left: 38px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 5));
    }
  }
`;

export const Slide = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  .logo-tokio {
    height: 60px;
  }
`;

export const ClientLogo = styled.img`
  height: 100px;
  width: auto;
  max-width: 250px;
`;

export const Header = styled.header`
  height: 150px;
  z-index: 9999;
  margin: 0 -15px;
  width: 100%;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 51%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 51%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 51%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );

  @media (max-width: 992px) {
    height: 80px;
    margin: 0;
  }
  // &.fixed{
  //   box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);
  //   position: fixed;
  //   top: 0;
  // }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  background-color: #fbfbfb;
  z-index: 99999;
  overflow: none;
  .container {
    max-width: 1400px;
    @media (max-width: 1400px) {
      max-width: 100%;
    }
  }
  .btn {
    font-weight: 500;
  }
  // .top {
  //   width: 100%;
  //   height: 150px;
  //   z-index: 9999;
  //   background: #fff;
  //   @media(max-width: 992px){
  //     height: 80px;
  //     margin: 0;
  //   }
  //   @media(min-width: 992px){
  //     position: absolute;
  //     top: 0;
  //     left: 0px;
  //   }
  // }
  // .bottom {
  //   z-index: 999;
  //   height: calc(100%);
  //   width: calc(100%);
  //   min-height: calc(100%) !important;
  //   @media(max-width: 992px){
  //     padding-top: 80px;
  //   }
  //   @media(min-width: 992px){
  //     position: absolute;
  //     top: 0px;
  //     left: 0px;
  //     overflow-y: scroll;
  //     overflow-x: hidden;
  //   }
  // }
  button {
    height: 75px;
    min-width: 270px;
    @media (max-width: 992px) {
      height: 50px;
      min-width: 200px;
    }
  }
`;

export const MainMenu = styled.nav`
  padding-top: 60px;
  margin-left: 65px;
  display: flex;
  align-items: center;
  @media (max-width: 1230px) {
    display: none;
  }

  a {
    margin-right: 25px;
    color: #003a70;
    font-family: 'Montserrat', sans-serif;
    padding: 5px 0;
    border-bottom: 5px solid transparent;
    font-size: 14px;
    font-weight: 400;
    &.active {
      border-bottom: 5px solid #0ac9c1;
    }
    &:hover {
      border-bottom: 5px solid #0ac9c1;
      text-decoration: none;
    }
  }
`;

export const LoginButtons = styled.div`
  display: flex;
  align-items: center;
  padding-top: 52px;
  justify-content: flex-end;
  @media (max-width: 992px) {
    padding-top: 45px;
  }
  @media (max-width: 992px) {
    margin-right: -30px;
  }
  button {
    margin-left: 20px;
    min-width: 167px;
    height: 50px !important;
    @media (max-width: 992px) {
      height: 35px !important;
      font-size: 12px;
      min-width: 135px;
      &.btn-outline-primary {
        min-width: 100px;
      }
    }
    @media (max-width: 420px) {
      &.btn-outline-primary {
        min-width: 70px;
      }
    }
  }
  .btn-outline-primary {
    border: 0;
    &:active {
      color: #003a70 !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0) !important;
    }
  }
  .btn.btn-aqua {
    @media (max-width: 420px) {
      margin-left: 0px;
    }
  }
`;

export const Logo = styled.div`
  padding: 50px 0 0 0;
  @media (max-width: 1540px) {
    padding: 50px 0 0 50px;
  }
  @media (max-width: 992px) {
    padding: 50px 0 0 0;
  }
  img {
    width: 177px;
    height: auto;
    @media (max-width: 992px) {
      width: 89px;
    }
  }
`;

export const Footer = styled.footer`
  background-color: #003a70;
  padding: 160px 0 0 0;
  margin-top: -50px;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: 992px) {
    padding-top: 120px;
    margin-top: -85px;
  }

  .footer__wrapper {
    padding: 0 0 66px 0;
  }
  .footer__copyright {
    padding: 25px 0;
    p {
      font-weight: 600;
    }
  }
  hr {
    border: 0;
    border-top: 1px solid #ffffff;
    padding: 0;
    margin: 0;
  }
  h6 {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 40px;
    @media (max-width: 992px) {
      margin-top: 50px;
    }
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
    max-width: 320px;
    a {
      color: #ffffff;
      text-decoration: underline;
    }
  }
  .social {
    a {
      display: inline-block;
      color: #ffffff;
      margin-bottom: 10px;
      @media (max-width: 992px) {
        display: inline-block;
        margin-right: 20px;
      }
      svg {
        width: 23px;
        height: 23px;
      }
    }
  }
`;

export const ImageBg = styled.div`
  background-image: url(${bg});
  background-repeat: no-repeat;
  /* background-position: 25% 65%; */
  background-position: 20% 60%;
  background-size: 180%;
  /* background-size: 150%; */
  /* padding-top: 120px;
  padding-bottom: 50px; */
  @media (max-width: 992px) {
    display: none;
  }
  &.imageBg-mobile {
    display: none;
    @media (max-width: 992px) {
      height: 400px;
      background-size: 100%;
      background-position: 0 70%;
      display: block;
    }
    @media (max-width: 576px) {
      background-position: 25% 70%;
      height: 355px;
      background-size: 200%;
    }
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  .btn-aqua {
    height: 75px;
    margin-right: 40px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    button {
      width: 200px;
      margin: 20px auto 0 auto !important;
      height: 50px;
      &.btn-aqua {
        height: 50px;
      }
    }
  }
`;

export const Part1 = styled.div`
  padding: 80px 0 50px 0;
  position: relative;

  @media (max-width: 1540px) {
    padding: 30px 50px;
  }
  @media (max-width: 1024px) {
    padding: 50px 15px 0 15px;
  }

  &:before {
    content: ' ';
    width: 1530px;
    height: 1500px;
    position: absolute;
    top: -492px;
    right: -1355px;
    background-size: 100%;
    background-image: url(${bg});
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: -1;
    @media (max-width: 1540px) {
      width: 1050px;
      height: 1100px;
      top: -300px;
      right: -1012px;
    }
    @media (max-width: 992px) {
      right: 50%;
      margin-right: -450px;
      width: 700px;
      height: 1500px;
      top: initial;
      bottom: -770px;
    }
  }

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    line-height: 72px;
    font-weight: 400;

    @media (max-width: 1540px) {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 20px;
      margin-top: 0;
    }

    @media (max-width: 992px) {
      font-size: 28px;
      line-height: 50px;
      margin-top: 0;
    }
    @media (max-width: 576px) {
      margin-bottom: 0;
    }
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 35px;
    font-weight: 400;
    padding-right: 80px;
    @media (max-width: 1540px) {
      font-size: 16px;
      line-height: 30px;
    }
    @media (max-width: 992px) {
      line-height: 32px;
      font-size: 16px;
      padding-right: 0;
      margin-bottom: 360px;
    }
  }
  button {
    margin-top: 50px;
    margin-right: 65px;
    @media (max-width: 1540px) {
      margin-top: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Part2 = styled.div`
  padding: 200px 0 0 0;

  @media (max-width: 992px) {
    padding-top: 122px;
    .text-right {
      text-align: center !important;
    }
  }

  h2 {
    margin-bottom: 50px;
    color: #303030;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 100;
    @media (max-width: 992px) {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  button {
    @media (max-width: 992px) {
      margin-top: 30px;
    }
  }
`;

export const Part3 = styled.div`
  /* margin-top: 250px; */

  .part3__wrapper {
    padding-top: 250px;

    &:before {
      content: '';
      width: 1000px;
      height: 1000px;
      position: absolute;
      top: -75px;
      right: -955px;
      z-index: -1;
      pointer-events: none;
      background-image: url(${menina});
      background-size: auto;
      background-repeat: no-repeat;
      background-position: 70% 40%;
      @media (max-width: 992px) {
        display: none;
      }
    }

    @media (max-width: 1024px) {
      padding-top: 0;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 45px;
    .text-left {
      text-align: center !important;
    }
  }

  h2 {
    margin-bottom: 50px;
    color: #303030;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 400;
    @media (max-width: 992px) {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 40px;
    font-weight: 400;
    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 32px;
    }
  }
  .btn-aqua {
    margin-top: 50px;
    height: 75px;
    font-weight: 500;
    letter-spacing: auto;
    min-width: 270px;
    @media (max-width: 992px) {
      height: 50px;
    }
  }
  .bg-menina {
    background-image: url(${menina});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 25% 55%;
    padding-top: 250px;
    @media (max-width: 992px) {
      height: 400px;
      background-position: 80% 30%;
    }
    @media (max-width: 576px) {
      height: 300px;
      background-size: 250%;
      background-position: 70% 38%;
    }
  }
`;

export const Part4 = styled.div`
  margin-top: 200px;
  @media (max-width: 992px) {
    margin-top: 122px;
    .text-left {
      text-align: center !important;
    }
  }

  h2 {
    margin-bottom: 88px;
    color: #303030;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 100;
    @media (max-width: 992px) {
      font-size: 28px;
      margin-bottom: 60px;
    }
  }
  p {
    font-size: 18px;
    line-height: 40px;
    max-width: 774px;
  }
  button {
    margin-top: 50px;
    &.btn-aqua {
      font-weight: 500;
      @media (max-width: 992px) {
        float: none !important;
        margin-top: 0;
      }
    }
  }
  figure {
    img {
      width: 800px;
      object-fit: cover;
      object-position: -100px;
    }
  }
  .foguete {
    &:before {
      content: ' ';
      width: 800px;
      height: 800px;
      position: absolute;
      top: -13px;
      left: -635px;
      background-size: 100%;
      background-image: url(${foguete});
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: -1;

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
`;

export const Part5 = styled.div`
  margin-top: 100px;
  h2 {
    margin-bottom: 88px;
    color: #303030;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 100;
    @media (max-width: 992px) {
      font-size: 28px;
      margin-bottom: 40px;
    }
  }
  button {
    margin: 50px 15px;
    font-weight: 500;
  }
  .bg-5 {
    background-image: url(${landing4});
    background-size: auto;
    background-repeat: no-repeat;
    background-position: 70% 40%;
    @media (max-width: 992px) {
      height: 400px;
      background-position: 80% 30%;
    }
    @media (max-width: 576px) {
      height: 300px;
      background-size: 250%;
      background-position: 70% 38%;
    }
  }

  .btn-container {
    margin-top: 100px;
    button {
      height: 75px;
    }
  }

  .bg-consultores {
    &:before {
      content: '';
      width: 1200px;
      height: 1200px;
      position: absolute;
      top: -315px;
      right: -810px;

      background-image: url(${landing4});
      background-size: auto;
      background-repeat: no-repeat;
      background-position: 70% 40%;
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
`;

export const Part6 = styled.div`
  margin-top: 250px;

  @media (max-width: 992px) {
    margin-top: 120px;
  }

  h2 {
    margin-bottom: 88px;
    color: #303030;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 100;
    @media (max-width: 992px) {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
`;

export const Part7 = styled.div`
  padding: 250px 0 0 0;
  @media (max-width: 992px) {
    padding-top: 122px;
  }
  .bgForm {
    &:before {
      content: ' ';
      width: 1400px;
      height: 800px;
      position: absolute;
      top: -150px;
      right: -933px;
      background-size: 100%;
      background-image: url(${bg_footer});
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: -1;

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
`;

export const Part8 = styled.div`
  .part8__wrapper {
    padding-top: 250px;

    &:before {
      content: '';
      width: 1000px;
      height: 1000px;
      position: absolute;
      top: 170px;
      right: -955px;
      z-index: -1;
      pointer-events: none;
      background-image: url(${menino});
      background-size: auto;
      background-repeat: no-repeat;
      background-position: 70% 40%;
      @media (max-width: 992px) {
        display: none;
      }
    }

    @media (max-width: 992px) {
      padding-top: 122px;
      .text-right {
        text-align: center !important;
      }
    }

    @media (max-width: 1024px) {
      margin-top: 45px;
      .text-left {
        text-align: center !important;
      }
    }

    h2 {
      margin-bottom: 50px;
      color: #303030;
      font-family: 'Montserrat', sans-serif;
      font-size: 60px;
      font-weight: 100;

      @media (max-width: 992px) {
        font-size: 28px;
        margin-bottom: 25px;
      }
    }

    .bg-menino {
      background-image: url(${menino});
      background-size: auto;
      background-repeat: no-repeat;
      background-position: 25% 55%;
      padding-top: 250px;

      @media (max-width: 992px) {
        height: 400px;
        background-position: 80% 30%;
      }

      @media (max-width: 576px) {
        height: 300px;
        background-size: 250%;
        background-position: 70% 38%;
      }
    }
  }
`;

export const Part9 = styled.div`
  padding: 35px 0 0 0;

  @media (max-width: 992px) {
    padding-top: 122px;
    .text-right {
      text-align: center !important;
    }
  }

  h2 {
    margin-bottom: 50px;
    color: #303030;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    font-weight: 100;
    @media (max-width: 992px) {
      font-size: 28px;
      margin-bottom: 25px;
    }
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  button {
    @media (max-width: 992px) {
      margin-top: 30px;
    }
  }
`;

export const SliderFeatures = styled.div`
  display: flex;
  margin-top: 88px;
  padding: 20px 5px;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    margin-top: 60px;
  }
`;

export const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const ItemSliderFeatures = styled.div`
  margin-right: 40px;
  width: calc(100% - 40px);
  padding: 50px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);

  @media (max-width: 992px) {
    padding: 35px;
    margin-right: 0;
    margin-bottom: 40px;
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }

  figure {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
  }
  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #0ac9c1;
    margin-top: 23px;
    font-weight: 600;
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
  h3 {
    margin: 5px 0 22px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    @media (max-width: 992px) {
      font-size: 25px;
    }
  }
  p {
    font-size: 16px;
    line-height: 25px;
  }
  a {
    font-family: 'Montserrat', sans-serif;
    margin-top: 35px;
    color: #0ac9c1;
    text-decoration: underline;
    display: block;
  }
`;

export const ItemFeatures = styled.div`
  margin-right: 80px;
  max-width: 310px;
  width: 100%;

  @media (max-width: 1160px) {
    max-width: 255px;
  }

  @media (max-width: 992px) {
    margin: 0 auto !important;
  }

  @media (max-width: 1540px) {
    margin-right: 0;
  }

  &:nth-child(3) {
    margin-left: 165px;
  }

  @media (max-width: 992px) {
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 50px;
    &:nth-child(3) {
      margin-left: 0;
    }
  }

  img {
    width: 80px;
    height: 80px;
  }
  h3 {
    margin: 32px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    color: #003a70;
    @media (max-width: 992px) {
      font-size: 20px;
      font-weight: 100;
      margin: 35px 0 25px 0;
    }
  }
  p {
    font-family: 'Montserrat', sans-serif;
    max-width: 250px;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 35px;
    font-weight: 400;
    @media (max-width: 992px) {
      max-width: 100%;
    }
  }
  a {
    font-family: 'Montserrat', sans-serif;
    margin-top: 35px;
    color: #0ac9c1;
    text-decoration: underline;
    display: block;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 615px;
  background-color: #e8eff8;
  padding: 45px 80px;
  border-radius: 20px;
  margin-left: auto;
  @media (max-width: 992px) {
    max-width: 100%;
    margin-left: 0;
    padding: 35px;
  }

  h5 {
    margin-bottom: 80px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 60px;
    color: #003a70;
    @media (max-width: 992px) {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
  input,
  textarea {
    background-color: #ffffff;
    border-radius: 8px;
    height: 50px;
    padding: 15px 20px;
    border: 0;
    margin-bottom: 25px;
    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      color: #003a70;
    }
  }
  textarea {
    height: 170px;
  }
`;

export const Depoimentos = styled.div`
  display: flex;
  margin: 50px 0 0 0;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const Depoimento = styled.div`
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 40px 45px 80px 45px;
  margin-right: 40px;
  width: 440px;
  position: relative;

  @media (max-width: 992px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }

  figure {
    margin-bottom: 35px;
  }
  p {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 26px;
  }
  &:after {
    content: ' ';
    background-image: url(${icon_depoimento});
    display: block;
    position: absolute;
    width: 42px;
    height: 37px;
    right: 20px;
    bottom: 20px;
    background-size: cover;
  }

  @media (max-width: 992px) {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const WarningBox = styled.div`
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  left: 0;
  padding: 0;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 10px 10px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  @media (max-width: 768px) {
    min-height: 175px;
    padding: 30px 10px;
  }

  .warningBox__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      margin-bottom: 0;
      max-width: 80%;
      @media (max-width: 768px) {
        text-align: center;
        margin-bottom: 20px;
      }
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 24px;
        max-width: 100%;
        br {
          display: none;
        }
      }
    }
    button {
      max-width: 143px;
      height: 50px;
      min-width: 143px;
      @media (max-width: 768px) {
        max-width: 300px;
        width: 100%;
      }
      @media (max-width: 576px) {
        margin: 0 auto;
        /* max-width: 100%; */
        width: 100%;
      }
    }
  }
`;

export const SliderProducts = styled.div`
  display: flex;
  padding: 20px 5px;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    margin-top: 60px;
  }
`;

export const ItemSliderProducts = styled.div`
  position: relative;
  height: 398px;
  margin-right: 40px;
  width: calc(100% - 40px);
  padding: 50px 24px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    padding: 30px;
    margin-right: 0;
    margin-bottom: 40px;
    width: 100%;
  }

  @media (max-width: 992px) {
    padding: 35px;
    margin-right: 0;
    margin-bottom: 40px;
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
    height: auto;
  }

  &:last-child {
    margin-right: 0;
  }
  
  h3 {
    margin: 5px 0 22px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 26px;
    color: #003A70;
    display: flex;
    justify-content: center;
    line-height: 180%;
    font-weight: 500;

    @media (max-width: 1200px) {
      font-size: 20px;
    }
    
    @media (max-width: 992px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  
  p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    letter-spacing: 0.06em;

    @media (max-width: 1200px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

export const BtnContainerProducts = styled.div`
  display: flex;
  position: absolute;
  top: 300px;

  @media (max-width: 992px) {
    flex-direction: row;
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 60px;
      width: 60px;
    }

    button {
      width: 200px;
      height: 50px;
    }
  }
  
  .btn-aqua {
    height: 60px;
    min-width: 200px;
    margin-left: 38px;

    @media (max-width: 1200px) {
      display: block;
      min-width: auto;
      width: 170px;
      margin-right: 10px;
      position: relative;
    }
    
    @media (max-width: 480px) {
      display: block;
      min-width: 130px;
    }
  }
`;
