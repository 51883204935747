import { IAction } from '../../types/common';
import { IUserState } from '../../types/user';

const NAME = 'USER';

export const types = {
  CONTACT_EMAIL: `${NAME}/CONTACT_EMAIL`,
  CONTACT_EMAIL_FEEDBACK: `${NAME}/CONTACT_EMAIL_FEEDBACK`
};

export const actions = {
  contactEmail: (name: string, email: string, message: string): IAction => ({ type: types.CONTACT_EMAIL, payload: { name, email, message } }),
  contactEmailFeedback: (feedbackMessage: string): IAction => ({ type: types.CONTACT_EMAIL_FEEDBACK, payload: { feedbackMessage } })
};

const initialState: IUserState = {
  isFetching: false
};

export default (state = initialState, action: IAction): IUserState => {
  const { type, payload } = action;
  switch (type) {
    case types.CONTACT_EMAIL_FEEDBACK:
      return {
        ...state,
        ...payload,
        isFetching: false
      };
      default:
        return state;
  }
};
