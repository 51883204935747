import { IUserState } from '../types/user';

// Mock localStorage when it is not allowed
let localStorage: Storage;
try {
  localStorage = window.localStorage; // eslint-disable-line
} catch (error) {
  localStorage = {
    getItem: () => null,
    setItem: () => null,
    removeItem: () => null,
    length: 0,
    clear: () => null,
    key: () => null
  };
}

export const loadState = (key: string): IUserState | undefined => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

// FIXME: set state type after defining reducers types
export const saveState = (state: any, key: string): void => { // eslint-disable-line
  try {
    const serializedState = state ? JSON.stringify(state) : '';
    localStorage.setItem(key, serializedState);
  } catch (error) {
    console.warn(`Error saving Redux state: ${error}`); // eslint-disable-line
  }
};

export const getToken = (): string => loadState('accessToken') ? String(loadState('accessToken')) : '';
