/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { string, object, ObjectSchema } from 'yup';
import { GrClose } from 'react-icons/gr';

import { PAGE_REGISTER, PAGE_LOGIN } from '../../constants';
import { actions as userActions } from '../../reducers/user';
import { IState } from '../../types/state';
import Button from '../../components/Button';
import selo from '../../assets/images/selo-melhores-do-seguro.png';
import icon1 from '../../assets/images/icons/landing1.png';
import icon2 from '../../assets/images/icons/landing2.png';
import icon3 from '../../assets/images/icons/landing3.png';
import produto1 from '../../assets/images/icons/produto1.png';
import produto2 from '../../assets/images/icons/produto2.png';
import produto3 from '../../assets/images/icons/produto3.png';
import landing1 from '../../assets/images/landing-icon-1.png';
import landing2 from '../../assets/images/landing-icon-2.png';

import logo_acoy from '../../assets/images/acoy-logo.svg';
import logo1 from '../../assets/images/logos/logo_berkley.svg';
import logo4 from '../../assets/images/logos/logo_junto.svg';
import logo5 from '../../assets/images/logos/logo-bancobmg.png';
import logo8 from '../../assets/images/logos/jns.png';
import logoTokio from '../../assets/images/logos/tokio-horizontal-sem-assinatura-fundo-claro.png';

import { Quiz } from './components/quiz';
import {
  Container,
  Logo,
  Part1,
  Part2,
  Part4,
  Part6,
  Part7,
  Part8,
  Part9,
  BtnContainer,
  SliderFeatures,
  ItemSliderFeatures,
  Features,
  ItemFeatures,
  Form,
  Footer,
  MainMenu,
  Header,
  LoginButtons,
  WarningBox,
  ContentSlide,
  SlideTrack,
  ClientLogo,
  Slide,
  SliderProducts,
  ItemSliderProducts,
  BtnContainerProducts
} from './styles';

const FormSchema: ObjectSchema = object().shape({
  name: string().required('Campo Obrigatório'),
  email: string().required('Campo Obrigatório'),
  message: string().required('Campo Obrigatório')
});

interface IForm {
  name?: string;
  email?: string;
  message?: string;
}

interface IFormTouch {
  name?: boolean;
  email?: boolean;
  message?: boolean;
}

function LandingPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const feedback = useSelector((state: IState) => state.user.feedbackMessage);

  const [form, setForm] = useState<IForm>({
    name: '',
    email: '',
    message: ''
  });
  const [formTouch, setFormTouch] = useState<IFormTouch>({});
  const [modal, setModal] = useState(false);
  const [displayCookie, setDisplayCookie] = useState(false);

  const handleLogin = () => {
    // history.push(PAGE_LOGIN);
    window.location.assign(PAGE_LOGIN);
  };

  useEffect(() => {
    const el = document.getElementsByTagName('body')[0];
    el.className = modal ? 'body-modal' : '';
  }, [modal]); // eslint-disable-line
  /*
  useEffect(() => {
    const { search } = history.location;
    if (search.includes('?error=') && search[0] === '?') {
      localStorage.removeItem('accessToken');
      history.push(`${ERROR}/${search.slice(1, search.length)}`);
    } else if (getToken()) {
      history.push(WELCOME);
    }
  }, [history.location]); // eslint-disable-line
*/
  useEffect(() => {
    if (feedback) {
      setModal(true);
    }
  }, [feedback]); // eslint-disable-line

  useEffect(() => {
    if (!modal) {
      dispatch(userActions.contactEmailFeedback(''));
    }
  }, [modal]); // eslint-disable-line

  useEffect(() => {
    setDisplayCookie(!localStorage.getItem('banner'));
  }, []); // eslint-disable-line

  const handleChange = (name: string, value: string | File | null): void => {
    setForm({ ...form, [name]: value });
  };

  const handleTouch = (name: string): void => {
    const touch: IFormTouch = formTouch;
    touch.name = true;
    setFormTouch(touch);
  };

  const handleValidate = (): Promise<boolean> =>
    FormSchema.validate(form, { abortEarly: false })
      .then(() => true)
      .catch(yupError => false);

  const handleSubmit = async () => {
    if (await handleValidate()) {
      dispatch(userActions.contactEmail(form.name!, form.email!, form.message!));
      setForm({
        name: '',
        email: '',
        message: ''
      });
    }
  };

  return (
    <Container className="container-fluid header-page scrollbar">
      {modal && <div className="overlay" />}
      {/* <div className="top"> */}
      <Header className="">
        <div className="container">
          <div className="row">
            <div className="col-4 col-lg-2">
              <Logo>
                <a href="/">
                  <img src={logo_acoy} title="Acoy" alt="Acoy" />
                </a>
              </Logo>
            </div>
            <div className="col-1 col-lg-7 navHeader">
              <MainMenu>
                {/* adicioar a class active para o item em destaque */}
                <a href="#sobre" title="Sobre a Acoy">
                  Sobre a Acoy
                </a>
                <a href="#seguradoras" title="Seguradoras">
                  Seguradoras
                </a>
                <a href="#duvidas" title="Dúvidas">
                  Dúvidas
                </a>
                <a href="#beneficios" title="Benefícios">
                  Benefícios
                </a>
                <a href="#contato" title="Contate-nos">
                  Contate-nos
                </a>
              </MainMenu>
            </div>
            <div className="col-6 col-lg-3 text-right">
              <LoginButtons>
                <Button className="btn-outline-primary" label="Entrar" onClick={handleLogin} />
                <Button className="btn-aqua" label="Crie sua conta" onClick={() => window.location.assign(PAGE_REGISTER)} />
              </LoginButtons>
            </div>
          </div>
        </div>
      </Header>
      {/* </div> */}
      {/* <div className="bottom"> */}
      <div className="container">
        <div className="row">
          <Part1 className="part1 col-12 col-lg-6">
            <h1>Corretor de Seguros, chegou a plataforma que vai simplificar e agilizar a sua emissão de Seguro!</h1>
            {/* <ImageBg className="imageBg-mobile" /> */}
            <p>
              Acoy é o seu assessor digital de seguros. Agora você corretor pode cotar, emitir e gerenciar as apólices dos seus
              clientes em minutos, tudo 100% online e gratuito.
            </p>
            <BtnContainer>
              <Button className="btn-aqua" label="Crie sua conta" onClick={() => window.location.assign(PAGE_REGISTER)} />
              <Button className="btn-default" label="Já tenho conta" onClick={handleLogin} />
            </BtnContainer>
          </Part1>
          {/* <ImageBg className="col-12 col-lg-6" /> */}
        </div>

        <Part9 className="row part9" id="produtos">
          <div className="col-12">
            <SliderProducts>
              <ItemSliderProducts>
                <h3>Licitações</h3>
                <p>
                  Produto certo para seus clientes que precisam apresentar uma garantia da proposta em editais de licitações da
                  administração pública ou empresa privada.
                </p>
                <BtnContainerProducts>
                  <img src={produto1} alt="" />
                  <Button className="btn-aqua" label="Saiba mais" onClick={() => window.location.assign(PAGE_REGISTER)} />
                </BtnContainerProducts>
              </ItemSliderProducts>

              <ItemSliderProducts>
                <h3>Depósito Recursal</h3>
                <p>
                  Opção para empresas que precisam caucionar um processo trabalhista e resguardar seu capital no docorrer dos
                  trâmites legais, concedendo unicamente o Seguro Garantia Judicial como garantia.
                </p>
                <BtnContainerProducts>
                  <img src={produto2} alt="" />
                  <Button className="btn-aqua" label="Saiba mais" onClick={() => window.location.assign(PAGE_REGISTER)} />
                </BtnContainerProducts>
              </ItemSliderProducts>

              <ItemSliderProducts>
                <h3>Garantia de Execução</h3>
                <p>
                  Seguro que garante a assinatura de contratos para Construção, Prestação de Serviços ou Fornecimento. Usado tanto
                  em contratos públicos como contratos entre empresas privadas.
                </p>
                <BtnContainerProducts>
                  <img src={produto3} alt="" />
                  <Button className="btn-aqua" label="Saiba mais" onClick={() => window.location.assign(PAGE_REGISTER)} />
                </BtnContainerProducts>
              </ItemSliderProducts>
            </SliderProducts>
          </div>
        </Part9>

        <Part6 className="row part6" id="seguradoras">
          <div className="col-12 text-center">
            <h2>Seguradoras parceiras</h2>
          </div>
          <ContentSlide>
            <SlideTrack>
              <Slide>
                <ClientLogo className="logo-tokio" src={logoTokio} />
              </Slide>
              <Slide>
                <ClientLogo src={logo8} />
              </Slide>
              <Slide>
                <ClientLogo src={logo4} />
              </Slide>
              <Slide>
                <ClientLogo src={logo1} />
              </Slide>
              <Slide>
                <ClientLogo src={logo5} />
              </Slide>
              <Slide>
                <ClientLogo className="logo-tokio" src={logoTokio} />
              </Slide>
              <Slide>
                <ClientLogo src={logo8} />
              </Slide>
              <Slide>
                <ClientLogo src={logo4} />
              </Slide>
              <Slide>
                <ClientLogo src={logo1} />
              </Slide>
              <Slide>
                <ClientLogo src={logo5} />
              </Slide>
              <Slide>
                <ClientLogo className="logo-tokio" src={logoTokio} />
              </Slide>
              <Slide>
                <ClientLogo src={logo8} />
              </Slide>
              <Slide>
                <ClientLogo src={logo4} />
              </Slide>
              <Slide>
                <ClientLogo src={logo1} />
              </Slide>
              <Slide>
                <ClientLogo src={logo5} />
              </Slide>
            </SlideTrack>
          </ContentSlide>
        </Part6>

        <Part2 className="row part2" id="sobre">
          <div className="col-12">
            <h2>Sobre a Acoy</h2>
          </div>
          <div className="col-12 col-lg-8">
            <p>
              Acoy é uma plataforma 100% digital que conecta sua corretora com as principais seguradoras. Usando novas tecnologias,
              nós integramos os sistemas das seguradoras, permitindo que você corretor, compare e emita sua apólice em um único
              local. Garantindo uma maior agilidade e transparência na sua gestão das apólices.
              <br />
              Destaque como <strong>Melhores do Seguro 2021</strong> na cerimônia organizada pela revista Apólice em sua
              11ª edição, a Acoy foi uma das 27 empresas premiadas entre 124 participantes, recebendo
              a premiação com a case “Insurtech cria plataforma para emissão de seguro garantia”.
            </p>
          </div>
          <div className="col-12 col-lg-4 text-right">
            {/* <figure> */}
            <img src={selo} alt="" width={200} height={200} />
            {/* </figure> */}
            {/* <Button className="btn-default" label="Veja mais" onClick={() => window.location.assign(PAGE_REGISTER)} /> */}
          </div>
          <div className="col-12">
            <SliderFeatures>
              <ItemSliderFeatures>
                <figure>
                  <img src={icon1} alt="" />
                </figure>
                <span>Passo 1</span>
                <h3>Cadastre-se</h3>
                <p>Faça um cadastro e comece a usar a ferramenta.</p>
              </ItemSliderFeatures>

              <ItemSliderFeatures>
                <figure>
                  <img src={icon2} alt="" />
                </figure>
                <span>Passo 2</span>
                <h3>Faça suas cotações</h3>
                <p>
                  Informando o prazo do contrato ou licitação, você obtém a cotação da apólice na hora e compara mais de 3
                  seguradoras.
                </p>
              </ItemSliderFeatures>

              <ItemSliderFeatures>
                <figure>
                  <img src={icon3} alt="" />
                </figure>
                <span>Passo 3</span>
                <h3>Emita a apólice</h3>
                <p>Selecionando as propostas mais competitivas e conclua a operação.</p>
              </ItemSliderFeatures>
            </SliderFeatures>
          </div>
        </Part2>
        {/*
        <Part8 className="row part8" id="duvidas">
          <div className="col-12 col-lg-8 part8__wrapper">
            <h2>Dúvidas mais comuns</h2>
            <Quiz
              question="Por que minha corretora deveria emitir as apólices na plataforma Acoy e não direto com as seguradoras ? Qual a
                    maior vantagem para o corretor que usa a ferramenta ?"
              answer="Nossa plataforma integrou os sistemas das principais seguradoras em apenas um local, permitindo a cotação,
                comparação e emissão de apólices em menos de 3 minutos. Com a Acoy, sua corretora vai ganhar agilidade e
                transparência no processo, melhorando a satisfação de seus clientes, além de reduzir custos, tanto em
                horas/pessoas como em backoffice (custos internos de estrutura)."
            />

            <Quiz
              question="Como é a remuneração da Acoy ?"
              answer="A Acoy não cobra um fee mensal das corretoras que utilizam a plataforma. Melhor do que isso, os 15% de comissão
                das corretoras está garantido e será pago diretamente pela seguradora ao corretor usuário da ferramenta. A
                remuneração da Acoy vem de acordos comerciais que ela mantém com as seguradoras."
            />

            <Quiz
              question="Terei acesso às cotações e apólices emitidas ?"
              answer="Sim, nossa plataforma dispõe de um dashboard (painel de controle) de fácil visualização integrando todas as
                apólices cotadas e emitidas em um único lugar. Também é possível personalizar a visualização para cada
                funcionário."
            />

            <Quiz
              question="Terei um relatório de comissões a receber e recebidas ?"
              answer="Sim, nossa plataforma dispõe de um dashboard (painel de controle) de fácil visualização, integrando todas as
                apólices cotadas e emitidas em um único lugar. Também é possível personalizar a visualização para cada
                funcionário, permitindo visualizar as comissões a receber ou recebidas."
            />

            <Quiz
              question="Posso cotar de qualquer computador ?"
              answer="Sim, com o seu usuário e senha você pode acessar de qualquer computador, ou até mesmo do seu celular."
            />

            <Quiz
              question="Posso cadastrar funcionários da corretora para usar a ferramenta? Posso cadastrar um usuário para ter acesso
                    somente a uma empresa no sistema ?"
              answer="Sim, o principal gestor da corretora terá acesso total sobre o cadastro dos seus funcionários, personalizando as
                permissões dos usuários."
            />

            <Quiz
              question="Minha corretora aparecerá nas apólices emitidas?"
              answer="Seguimos os padrões das seguradoras. Se a seguradora disponibilizar o nome da corretora na apólice, o nome
                aparecerá na apólice emitida."
            />

            <Quiz
              question="A comissão é paga direto pela seguradora ou pela Acoy ?"
              answer="Como será emitido por co-corretagem, sua comissão será paga direto pela seguradora na sua conta cadastrada por
                ela."
            />

            <Quiz
              question="Como cancelo uma apólice ?"
              answer="Enviando um email para nossa equipe que ficará responsável por todos endossos (seja alteração ou cancelamento)
                junto às seguradoras parceiras."
            />

            <BtnContainer>
              <Button className="btn-aqua" label="Ir para o cadastro" onClick={() => window.location.assign(PAGE_REGISTER)} />
              <Button className="btn-default" label="Saiba mais" onClick={() => window.location.assign(PAGE_REGISTER)} />
            </BtnContainer>
          </div>
        </Part8> */}

        {/* <Part3 className="row part3" id="depoimentos"> */}
        {/*  <div className="col-12 col-lg-8 part3__wrapper"> */}
        {/*    <h2>Queremos você com a gente</h2> */}
        {/*    <p> */}
        {/*      Na Acoy, acreditamos que os corretores tem um papel fundamental, uma visão de assessor de negócios para os seus */}
        {/*      clientes. Seja um parceiro Acoy e tenha a ferramenta certa para agilizar e digitalizar o seu dia a dia. */}
        {/*    </p> */}
        {/*  </div> */}
        {/*  <div className="col-12 text-left"> */}
        {/*    <Button className="btn-aqua" label="Seja um parceiro Acoy" onClick={() => window.location.assign(PAGE_REGISTER)} /> */}
        {/*  </div> */}
        {/* </Part3> */}

        <Part4 className="row part4" id="beneficios">
          <div className="col-12 text-center">
            <h2>A melhor plataforma para sua corretora</h2>
          </div>

          <div className="col-12 col-lg-5 text-right" />
          <div className="col-12 col-lg-7 text-left foguete">
            <Features>
              <ItemFeatures>
                <img src={landing1} alt="" />
                <h3>3 Minutos</h3>
                <p>Esse é o tempo que você vai levar para comparar e emitir sua apólice.</p>
              </ItemFeatures>
              <ItemFeatures>
                <img src={landing2} alt="" />
                <h3>100% Digital</h3>
                <p>Gerencie suas apólices de diferentes seguradoras em uma única plataforma.</p>
              </ItemFeatures>
            </Features>
            <Button className="btn-aqua float-right" label="Comece agora" onClick={() => window.location.assign(PAGE_REGISTER)} />
          </div>
        </Part4>

        <Part7 className="row part7" id="contato">
          <div className="col-12 col-lg-6 text-right bgForm">
            <Form autoComplete="off">
              <h5>Contato</h5>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Nome"
                value={form.name}
                onChange={e => handleChange('name', e.currentTarget.value)}
                onBlur={() => {
                  handleValidate();
                  handleTouch('name');
                }}
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                value={form.email}
                onChange={e => handleChange('email', e.currentTarget.value)}
                onBlur={() => {
                  handleValidate();
                  handleTouch('email');
                }}
              />
              <textarea
                name="message"
                id="message"
                placeholder="Mensagem"
                value={form.message}
                onChange={e => handleChange('message', e.currentTarget.value)}
                onBlur={() => {
                  handleValidate();
                  handleTouch('message');
                }}
              />
              <Button type="button" className="btn-primary" label="Enviar" onClick={handleSubmit} />
            </Form>
          </div>
        </Part7>

        {displayCookie && (
          <WarningBox>
            <div className="container">
              <div className="row">
                <div className="col-12 warningBox__wrapper">
                  <p>
                    Este site usa cookies para melhorar a sua experiência. Ao continuar a navegar estará a aceitar a sua
                    utilização.
                  </p>
                  <Button
                    type="button"
                    className="btn-aqua"
                    label="ACEITAR"
                    onClick={() => {
                      setDisplayCookie(false);
                      localStorage.setItem('banner', '1');
                    }}
                  />
                </div>
              </div>
            </div>
          </WarningBox>
        )}
      </div>

      <Footer>
        <div className="container">
          <div className="row footer__wrapper">
            <div className="col-12 col-lg-3">
              <h6>Seguradoras</h6>
              <p>Tokio Marine Seguradora</p>
              <p>JNS Seguradora</p>
              <p>Juntos Seguros</p>
              <p>Berkley Seguradora</p>
              <p>BMG Seguradora</p>
            </div>
            <div className="col-12 col-lg-3">
              <h6>Índice</h6>
              <p>
                <a href="#sobre" title="Sobre a Acoy">
                  Sobre a Acoy
                </a>
              </p>
              <p>
                <a href="#seguradoras" title="Seguradoras">
                  Seguradoras
                </a>
              </p>
              <p>
                <a href="#duvidas" title="Dúvidas">
                  Dúvidas mais comuns
                </a>
              </p>
              <p>
                <a href="#beneficios" title="Benefícios">
                  Benefícios
                </a>
              </p>
            </div>
            <div className="col-12 col-lg-3">
              <h6>Sobre</h6>
              <p>
                Acoy é um Marketplace de Seguro Garantia 100% Online! Somos a primeira plataforma que permite você realizar
                cotações e emitir suas apólices de seguro garantia totalmente digital.
                <br />
                <strong>
                  Fale conosco: <a href="tel:+5501143010032">(11) 4301-0032</a>
                </strong>
              </p>
            </div>
            <div className="col-12 col-lg-3 social">
              <h6>Siga-nos</h6>
              <div>
                <a target="_BLANK" rel="noopener noreferrer" href="https://www.linkedin.com/company/acoy/">
                  <FaLinkedin />
                </a>
              </div>
              <div>
                <a target="_BLANK" rel="noopener noreferrer" href="https://www.instagram.com/acoyseguros/?hl=pt-br">
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className="row footer__copyright">
            <div className="col-12">
              <p>Todos os direitos reservados</p>
            </div>
          </div>
        </div>
      </Footer>
      {/* </div> */}
      {modal && (
        <div className="modal">
          <div className="btn-close">
            <GrClose onClick={() => setModal(false)} />
          </div>
          <div className="modal__content">
            <p>{feedback}</p>
          </div>
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setModal(false);
              }}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </Container>
  );
}

export default LandingPage;
