/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
import { call, put, takeEvery } from 'redux-saga/effects';
import { types, actions as userActions } from '../../reducers/user';

import {
  sendContactEmail
} from '../../api/users';
import { IAction } from '../../types/common';

function* onSendContactEmail(action: IAction) { // eslint-disable-line
  try {
    const { email, name, message } = action.payload;
    yield call(sendContactEmail, email!, name!, message!);
    yield put(userActions.contactEmailFeedback('Email de contato enviado com sucesso!'));
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  } catch (error) {
    console.log('error sending email');
  }
}

export default function* watchUser() {
  // eslint-disable-line
  yield takeEvery(types.CONTACT_EMAIL, onSendContactEmail);
}
