import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
   * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
   }

   html,
   body,
   #root {
      min-height: 100%;
   }

   #root{
      display: flex;
      min-height: 100vh;
   }

   body {
      -webkit-font-smoothing: antialiased !important;
   }

   body,
   input,
   button {
      color: #333;
      font-size: 16px;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
   }

   button {
      cursor: pointer;
   }

`;
