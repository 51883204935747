import React from 'react';
import { Spinner } from 'react-bootstrap';

export interface ButtonProps {
  label?: string;
  className: string;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactElement;
  onClick?: () => void;
}

function Button({ label, className, loading, onClick, children, type, disabled }: ButtonProps): React.ReactElement {
  return (
    <button className={`btn ${className}`} onClick={onClick} type={type || 'submit'} disabled={disabled}>
      {
        loading
          ? <Spinner animation="border" role="status" />
          : label || children
      }
    </button>
  );
}

export default Button;
